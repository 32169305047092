import { useAppState } from '../../hooks/useAppState';

import developmentsIcon from '../../assets/images/development-s.svg';
import pdsIcon from '../../assets/images/pds.svg';
import { useForm } from 'react-hook-form';
import { Form, FormGroup, Label } from 'reactstrap';
import { WrappedInput } from '../../components/WrappedInput';
import StepActions from './step-actions';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect } from 'react';
import AsyncTypeaheadComponent from '../../components/AsyncTypeahead';
import DropDown from '../../components/DropDown';
import { URL_CONSTANTS } from '../../store/constant';
import { mergeObjs } from '../../utils/ObjectUtils';
import GenericStepForm from './generic-step-form';
import { WrappedNumberInput } from '../../components/WrappedNumberInput';

function DevelopmentStrategyStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();

  const { register, setError, handleSubmit, formState, watch, setValue, reset, trigger } = useForm({
    defaultValues: mergeObjs(stepValues, {
      f_24H_ops: 'yes',
      f_beer_cave: 'yes',
      isRatailTenants: 'yes',
      quickServiceRestaurant: 'fastfoodfranchise',
      numCarWash: 0,
      retailTenantType: 'None',
      retailTenantSize: 0,
      qsrBrand: 'None',
      qsrSize: 0,
      storeType: 'None',
      storeSize: 0,
      f_freezer_door: 0,
      f_cooler_door: 0,
    }),
    mode: 'onSubmit',
  });
  const { errors } = formState;

  const selectedViewProjectTemplate = (selected) => {
    selected = selected[0];

    if (selected == undefined) return;
    setValue('gasBrandConsideration', selected.gasBrandConsideration);

    setValue('numMPD', selected.numMPD);
    setValue('numAutoDieselHose', selected.numAutoDieselHose);
    setValue('numCarWash', selected.num_car_wash);

    if (selected.num_car_wash == null || selected.num_car_wash === '') {
      setValue('numCarWash', 0);
    }

    setValue('qsrBrand', selected.qsrBrand);
    setValue('qsrSize', selected.qsrSize);
    setValue('retailTenantType', selected.retailTenantType);
    setValue('retailTenantSize', selected.retailTenantSize);
    setValue('store_type', selected.store_type);
    setValue('storeSize', selected.storeSize);
    setValue('f_freezer_door', selected.f_freezer_door);
    setValue('f_cooler_door', selected.f_cooler_door);
    setValue('f_beer_cave', selected.f_beer_cave);
    setValue('f_24H_ops', selected.f_24H_ops);
  };

  let isRatailTenants = watch('isRatailTenants');
  let quickServiceRestaurant = watch('quickServiceRestaurant');

  return (
    <GenericStepForm step={step} setStep={setStep} submitHandler={handleSubmit}>
      <div className="section-header">
        <img src={developmentsIcon} alt={''} />
        <h2 className="section-heading">Proposed Development Strategy</h2>
      </div>
      <div className="row">
        <div className="col-lg-7">
          {stepValues?.facType == 'commercial' ? (
            <div>
              {/* <div className="row">
                <div className="col-lg-12">
                  <label className="form-label">Please select proposed development strategy</label>
                </div>
                <div className="col-lg-12">
                  <DropDown
                    id={'viewProjectTemplate'}
                    searchUri={`/viewProjectTemplate`}
                    placeholder={'Select strategy'}
                    labelField={'template_name'}
                    onChange={(value) => {
                      setValue('template_name', value);
                      selectedViewProjectTemplate(value);
                    }}
                    defaultValues={stepValues?.template_name}
                  />
                </div>
              </div> */}
              <div className="row">
                <div className="col-lg-12">
                  <label className="form-label">Please make your selection</label>
                </div>
                <div className="col-lg-6">
                  <div className="mb-4">
                    <WrappedInput
                      {...register('gasBrandConsideration', { required: 'Required' })}
                      type="text"
                      className="required-field"
                      name={'gasBrandConsideration'}
                      placeholder="Gas Brand"
                      onFocus={async () => {
                        trigger('gasBrandConsideration');
                      }}
                      onBlur={async () => {
                        trigger('gasBrandConsideration');
                      }}
                    />
                    <ErrorMessage errors={errors} name="gasBrandConsideration" as={'p'} className={'error-msg'} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <WrappedNumberInput
                      errors={errors}
                      registerhandler={register}
                      min={0}
                      max={100}
                      required={true}
                      name={'numMPD'}
                      placeholder="# of MPDs"
                      onFocus={() => {
                        trigger('numMPD');
                      }}
                      onBlur={() => {
                        trigger('numMPD');
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <WrappedNumberInput
                      errors={errors}
                      registerhandler={register}
                      min={0}
                      max={100}
                      required={true}
                      name={'numAutoDieselHose'}
                      placeholder="# of Diesel Hoses"
                      onFocus={() => {
                        trigger('numAutoDieselHose');
                      }}
                      onBlur={() => {
                        trigger('numAutoDieselHose');
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <WrappedNumberInput
                      errors={errors}
                      registerhandler={register}
                      min={0}
                      max={100}
                      required={true}
                      name={'numCarWash'}
                      placeholder="# of Car Wash"
                      onFocus={() => {
                        trigger('numCarWash');
                      }}
                      onBlur={() => {
                        trigger('numCarWash');
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 mt-2">
                  <div className="mb-3">
                    <p style={{ fontWeight: 'bold' }}>Attached QSR (Quick Service Restaurant)</p>
                    <FormGroup check inline>
                      <Label check className="d-flex align-items-center">
                        <WrappedInput
                          {...register('quickServiceRestaurant')}
                          value={'fastfoodfranchise'}
                          type="radio"
                          name={'quickServiceRestaurant'}
                        />
                        <span className="pt-1"> Fast Food Franchise</span>
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check className="d-flex align-items-center">
                        <WrappedInput
                          {...register('quickServiceRestaurant')}
                          value={'privatedeli'}
                          type="radio"
                          name={'quickServiceRestaurant'}
                        />
                        <span className="pt-1">Private Deli</span>
                      </Label>
                    </FormGroup>
                  </div>
                </div>
              </div>

              {quickServiceRestaurant === 'privatedeli' ? (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <WrappedInput
                        {...register('qsrPrivateDeliBrand', { required: 'Required' })}
                        type="text"
                        className="form-control"
                        name={'qsrPrivateDeliBrand'}
                        placeholder="Private Deli"
                        value={watch("qsrPrivateDeliBrand")}
                        onFocus={() => {
                          trigger('qsrPrivateDeliBrand');
                        }}
                        onBlur={() => {
                          trigger('qsrPrivateDeliBrand');
                        }}
                      />
                      <ErrorMessage errors={errors} name="qsrPrivateDeliBrand" as={'p'} className={'error-msg'} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <WrappedNumberInput
                        errors={errors}
                        registerhandler={register}
                        min={0}
                        max={100000}
                        required={true}
                        name={'qsrPrivateDeliSize'}
                        placeholder="Private Deli Size in sq. ft"
                        value={watch("qsrPrivateDeliSize")}
                        onFocus={() => {
                          trigger('qsrPrivateDeliSize');
                        }}
                        onBlur={() => {
                          trigger('qsrPrivateDeliSize');
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <WrappedInput
                        {...register('qsrBrand', { required: 'Required' })}
                        type="text"
                        className="form-control"
                        name={'qsrBrand'}
                        placeholder="Fast Food Franchise"
                        value={watch("qsrBrand")}
                        onFocus={() => {
                          trigger('qsrBrand');
                        }}
                        onBlur={() => {
                          trigger('qsrBrand');
                        }}
                      />
                      <ErrorMessage errors={errors} name="qsrBrand" as={'p'} className={'error-msg'} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <WrappedNumberInput
                        errors={errors}
                        registerhandler={register}
                        min={0}
                        max={100000}
                        required={true}
                        name={'qsrSize'}
                        value={watch("qsrSize")}
                        placeholder="Fast Food Size in sq. ft"
                        onFocus={() => {
                          trigger('qsrSize');
                        }}
                        onBlur={() => {
                          trigger('qsrSize');
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-lg-12 mt-2">
                  <div className="mb-3">
                    <p style={{ fontWeight: 'bold' }}>Any retail tenants?</p>
                    <FormGroup check inline>
                      <Label check className="d-flex align-items-center">
                        <WrappedInput
                          {...register('isRatailTenants')}
                          value={'yes'}
                          type="radio"
                          name={'isRatailTenants'}
                        />
                        <span className="pt-1"> Yes</span>
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check className="d-flex align-items-center">
                        <WrappedInput
                          {...register('isRatailTenants')}
                          value={'no'}
                          type="radio"
                          name={'isRatailTenants'}
                        />
                        <span className="pt-1"> No</span>
                      </Label>
                    </FormGroup>
                  </div>
                </div>
              </div>

              {isRatailTenants == 'yes' && (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <WrappedNumberInput
                        errors={errors}
                        required={true}
                        registerhandler={register}
                        min={0}
                        max={100000}
                        className="form-control"
                        name={'noOfRetailTenant'}
                        placeholder="Number of retail tenants"
                        onFocus={() => {
                          trigger('noOfRetailTenant');
                        }}
                        onBlur={() => {
                          trigger('noOfRetailTenant');
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <WrappedNumberInput
                        errors={errors}
                        required={true}
                        registerhandler={register}
                        min={0}
                        max={100000}
                        className="form-control"
                        name={'retailTenantTotalSize'}
                        placeholder="total size in sq ft."
                        onFocus={() => {
                          trigger('retailTenantTotalSize');
                        }}
                        onBlur={() => {
                          trigger('retailTenantTotalSize');
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            ''
          )}

          <div className="mt-3">
            <label className="form-label">C-Store Detail</label>
          </div>
          <div className="row">
            {/* <div className="col-lg-6">
              <div className="mb-4">
                <WrappedInput
                  {...register('storeType', { required: 'Required' })}
                  type="text"
                  className="form-control"
                  name={'storeType'}
                  placeholder="Store type"
                  onFocus={() => {
                    trigger('storeType');
                  }}
                  onBlur={() => {
                    trigger('storeType');
                  }}
                />
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="mb-3">
                <WrappedNumberInput
                  errors={errors}
                  required={true}
                  registerhandler={register}
                  min={0}
                  max={100000}
                  className="form-control"
                  name={'storeSize'}
                  placeholder="Size in sq. ft"
                  onFocus={() => {
                    trigger('storeSize');
                  }}
                  onBlur={() => {
                    trigger('storeSize');
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <WrappedNumberInput
                  errors={errors}
                  required={true}
                  registerhandler={register}
                  min={0}
                  max={100}
                  className="form-control"
                  name={'f_cooler_door'}
                  placeholder="Num. of Cooler doors"
                  onBlur={() => {
                    trigger('f_cooler_door');
                  }}
                  onFocus={() => {
                    trigger('f_cooler_door');
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-4">
                <WrappedNumberInput
                  errors={errors}
                  required={true}
                  registerhandler={register}
                  min={0}
                  max={100}
                  className="form-control"
                  name={'f_freezer_door'}
                  placeholder="Num. of freezer doors"
                  onBlur={() => {
                    trigger('f_freezer_door');
                  }}
                  onFocus={() => {
                    trigger('f_freezer_door');
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mb-3"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mt-2">
              <div className="mb-3">
                <p style={{ color: '#7884A0' }}>Will you have a beer cave ?</p>
                <FormGroup check inline>
                  <Label check className="d-flex align-items-center">
                    <WrappedInput {...register('f_beer_cave')} value={'yes'} type="radio" name={'f_beer_cave'} />

                    <span className="pt-1"> Yes</span>
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check className="d-flex align-items-center">
                    <WrappedInput {...register('f_beer_cave')} value={'no'} type="radio" name={'f_beer_cave'} />

                    <span className="pt-1">No</span>
                  </Label>
                </FormGroup>
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <div className="mb-3">
                <p style={{ color: '#7884A0' }}>Will this site be 24 hours operation ?</p>
                <FormGroup check inline>
                  <Label check className="d-flex align-items-center">
                    <WrappedInput {...register('f_24H_ops')} value={'yes'} type="radio" name={'f_24H_ops'} />
                    <span className="pt-1"> Yes</span>
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check className="d-flex align-items-center">
                    <WrappedInput {...register('f_24H_ops')} value={'no'} type="radio" name={'f_24H_ops'} />
                    <span className="pt-1">No</span>
                  </Label>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="seciton-flex sf-wrapper">
            <img src={pdsIcon} />
          </div>
        </div>
      </div>
    </GenericStepForm>
  );
}

export default DevelopmentStrategyStep;
