import { useAppState } from '../../hooks/useAppState';

import logo from '../../assets/images/logo/logo.png';
import { useState } from 'react';
import ProjectTypeStep from './project-type-step';
import SiteLocationStep from './site-location-step';
import SiteDetailStep from './site-detail-step';
import DevelopmentStrategyStep from './development-strategy-step';
import SiteImageStep from './site-image-step';
import LeadStep from './lead-step';
import GenerateReportStep from './generate-report-step';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';



function CommercialSteps() {
  const { user } = useAuth();

  const navigate=useNavigate();
  const [stepValues, setStepValues] = useAppState();
  const [currentStep, setCurrentStepState] = useState(1);

  const [toggle, setToggle] = useState(false);


  const setCurrentStep = (step) => {
    if (step <= 1) {
      setCurrentStepState(step);
    } else if (stepValues.stepStatus[step - 1] === 'done') setCurrentStepState(step);
    else setCurrentStepState(currentStep);
  };

  const getStepClass = (step) => {
    if (step === currentStep) return 'current';
    else {
      if (!stepValues.stepStatus) return '';
      return stepValues.stepStatus[step] === 'done' ? 'done' : '';
    }
  };

  const StepListItem = ({ index, title }) => {
    return (
      <li
        role="tab"
        className={`${index === 1 ? 'first' : ''} ${getStepClass(index)}`}
        aria-disabled="false"
        aria-selected="false"
      >
        <a
          aria-controls="apartment-wizard-p-0"
          onClick={() => {
            if (stepValues.stepStatus[currentStep] === 'done') {
              setStepValues({ ...stepValues, ...{ stepSubmitInvoked: true, stepSubmitInvokedIndex: index } });
              return;
            }
            setCurrentStep(index);
          }}
        >
          <span className="number">{index}</span>
          <span>{title}</span>
        </a>
      </li>
    );
  };

  return (
    <div className={'wizard clearfix vertical'}>
      <div className={'steps clearfix'}>
        <div className="steps-text">
          <img src={logo} onClick={()=>
          {
            navigate("/")
          }} style={{cursor:"pointer"}}/>
          <p>
            Follow the simple 7 Steps to complete
            <br /> your analysis for <span className="color-primary">Commercial property</span>
          </p>
        </div>
        <ul className={'tabList'}>
          <StepListItem index={1} title="Project Type" />
          <StepListItem index={2} title=" Site Location" />
          <StepListItem index={3} title="Site Details" />
          <StepListItem index={4} title="Development Strategy" />
          <StepListItem index={5} title="Site Images" />
          <StepListItem index={6} title="Leads" />
          <StepListItem index={7} title="Generate Report" />
        </ul>
        
      </div>

      <div className="subStepHeader">
        <div className={toggle ? 'toggleMenu mb-2 active' : 'toggleMenu mb-2'}>
          <button
            onClick={() => {
              setToggle(!toggle);
            }}
            className="toggler-icon-btn-main"
          >
            <span className="toggler-icon-btn"></span>
            <span className="toggler-icon-btn"></span>
            <span className="toggler-icon-btn"></span>
          </button>
        </div>
        <ul className={toggle ? 'menuActive' : 'meneul'}>
          <li>
            {' '}
            <Link to="/">Home</Link>{' '}
          </li>
          {user && (
            <li>
              {' '}
              <Link to={'/submittedreports'}>Submitted Reports</Link>{' '}
            </li>
          )}

          <li>
            {' '}
            <Link to="/contact-us">Contact Us</Link>{' '}
          </li>
        </ul>
      </div>
      <div className={'content clearfix'}>
        {currentStep === 1 && <ProjectTypeStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 2 && <SiteLocationStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 3 && <SiteDetailStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 4 && <DevelopmentStrategyStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 5 && <SiteImageStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 6 && <LeadStep step={currentStep} setStep={setCurrentStep} />}
        {currentStep === 7 && <GenerateReportStep step={currentStep} setStep={setCurrentStep} />}
      </div>
    </div>
  );
}

export default CommercialSteps;
