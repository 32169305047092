import * as Yup from 'yup';

export const profileValidationSchema = Yup.object().shape({
  company_name: Yup.string().required('Company Name is required'),
  service_description: Yup.string().required('Service Description is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  phone_number: Yup.string().required('Phone number is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  region: Yup.string().required('Region is required'),
  postal_code: Yup.number().typeError('Postal Code must be a number').required('Postal Code is required'),
  country: Yup.string().required('Country is required'),
  website: Yup.string().url('Invalid URL').required('Website URL is required'),
});
export const otpValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .required('OTP is required')
    .matches(/^\d{6}$/, 'OTP must be a 6-digit number'),
  emailCode: Yup.string()
    .required('Email OTP is required')
    .matches(/^\d{6}$/, 'Email OTP must be a 6-digit number'),
});

export let AllRegex = {
  onlyAlphabets: /^[a-zA-Z]*$/,
  email:/^[A-Za-z][A-Za-z0-9._%+-]*@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  specialCharRegex : /[^a-zA-Z0-9\s-_]/
};
