import { useEffect, useState } from 'react';
import axiosInstance from '../services/axio-service';
import SubHeader from './sub-header';
import LoadingOverlay from 'react-loading-overlay-ts';
import CopyRightComponent from './copyright';
import Loader from '../components/Loader';
import leftarrow from '../assets/images/left-arrow.png';
import rightarrow from '../assets/images/right-arrow.png';

function SubmittedReports() {
  const [data, setDate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const pagesToShow = 5;

  const paginatedData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const totalPages = Math.ceil(data?.length / itemsPerPage);
  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get('/viewSubmittedReports/')
      .then((response) => {
         
        const sortedData = response?.data.sort((a, b) => {
          const dateA = new Date(a.created_at).getTime();
          const dateB = new Date(b.created_at).getTime();
          return dateB - dateA;
        });
        setDate(sortedData);
      })
      .catch((e) => {
        console.log('errr', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const headers = [
    { title: 'PID', prop: 'pid' },
    { title: 'Address', prop: 'address' },
    { title: 'City', prop: 'city' },
    { title: 'Type', prop: 'development_type' },
    { title: 'Facility', prop: 'facility_type' },
    { title: 'Project type', prop: 'project_type' },
    // { title: 'Location', prop: 'development_type' },
    { title: 'State type', prop: 'state' },
    { title: 'Title', prop: 'title' },
    { title: 'Zip code', prop: 'zip_code' },
    { title: 'Created At', prop: 'data' },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const getPageRange = () => {
    const midPoint = Math.floor(pagesToShow / 2);

    if (totalPages <= pagesToShow) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    } else if (currentPage <= midPoint) {
      return Array.from({ length: pagesToShow }, (_, index) => index + 1);
    } else if (currentPage > totalPages - midPoint) {
      return Array.from({ length: pagesToShow }, (_, index) => totalPages - pagesToShow + index + 1);
    } else {
      return Array.from({ length: pagesToShow }, (_, index) => currentPage - midPoint + index);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {isLoading && <Loader content="Fetching Reports" />}
      <section className="submitreport-banner ">
        <SubHeader />
        <div
          style={{
            padding: '170px 0 30px',
          }}
        >
          <div className="container w-auto">
            <div className="row align-items-center">
              <div className="col-md-12 col-12"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="submittedreportsWrapper mt-5">
        <div className="container mt-5">
          <div className="row">
            <div className="col col-12">
              <div className="LeadsTable" style={{ overflowX: 'auto' }}>
                <table style={{ width: '100%' }}>
                  <thead style={{ background: '#F0F3F4' }}>
                    <tr>
                      {headers?.map((head) => (
                        <td>{head.title}</td>
                      ))}
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody className="">
                    {paginatedData?.map((row, index) => (
                      <tr key={index}>
                        <td className="idTd">{row.pid}</td>
                        <td>{row.address}</td>
                        <td>{row.city}</td>
                        <td>{row.development_type}</td>
                        <td>{row.facility_type}</td>
                        <td>{row.project_type}</td>
                        {/* <td>{row.development_type}</td> */}
                        <td>{row.state}</td>
                        <td>{row.title}</td>
                        <td>{row.zip_code}</td>
                        <td>{new Date(row?.created_at).toLocaleString()}</td>

                        <td>
                          {' '}
                          <button
                            className=" "
                            onClick={() => {
                              window.open(
                                process.env.REACT_APP_SERVER_BASE_URL + row.report_path,
                                '_blank',
                                'noreferrer'
                              );
                            }}
                          >
                            Report
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="paginationWrapper d-flex justify-content-end mt-3">
                <div className="pagination">
                  <img
                    disabled={currentPage === 1}
                    style={{
                      width: '20px',
                      marginRight: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={handleFirstPage}
                    src={leftarrow}
                    alt=""
                  />
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="pagination-arrow"
                  >
                    &lt;
                  </button>

                  {getPageRange().map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={currentPage === page ? 'active' : ''}
                    >
                      {page}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === Math.ceil(data?.length / itemsPerPage)}
                    className="pagination-arrow"
                  >
                    &gt;
                  </button>

                  <img
                    style={{
                      width: '20px',
                      marginLeft: '5px',
                      transform: 'rotate(180deg)',
                      cursor: 'pointer',
                    }}
                    onClick={handleLastPage}
                    disabled={currentPage === totalPages}
                    src={leftarrow}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-3">
        <CopyRightComponent />
      </div>
    </div>
  );
}

export default SubmittedReports;
