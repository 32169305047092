import { Link } from 'react-router-dom';

import { useAppState } from '../../hooks/useAppState';
import siteLocationIcon from '../../assets/images/location.svg';

import { GoogleMap, LoadScript, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Form, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { WrappedInput } from '../../components/WrappedInput';
import StepActions from './step-actions';
import { ErrorMessage } from '@hookform/error-message';
import GenericStepForm from './generic-step-form';
import { toast } from 'react-toastify';
import { AllRegex } from '../../validation';
const MapComponent = forwardRef((props, ref) => {
  const [position, setPosition] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [mapCenter, setMapCenter] = useState(props.mapCenter);
  const mapRef = useRef(null);

  const mapLoaded = (map) => {
    mapRef.current = map;
    // if (props.markerPostion) {
    //   setMarkerPositionAndCenterMap(props.markerPostion);
    // }

    if (props.mapLoaded !== null) {
      props.mapLoaded(map, props, ref);
    }
  };

  const setMarkerPositionAndCenterMap = (lntLng) => {
    setPosition(lntLng);

    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(lntLng);
    setZoom(15);
    mapRef.current.fitBounds(bounds);
  };

  useImperativeHandle(ref, () => ({
    handleAddressSearch(address) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (result) => {
        if (result && result[0]) {
          setMarkerPositionAndCenterMap(result[0].geometry.location);
          props.handlePositionResult(result[0].geometry.location);
        }
      });
    },
    handleGeoCoderResult(location) {
      setMarkerPositionAndCenterMap(location);
      props.handlePositionResult(location);
    },
    setMarkerPositionAndCenterMap(loc) {
      setMarkerPositionAndCenterMap(loc);
    },
  }));

  const handleMapClick = (event) => {
    const { latLng } = event;
    const geocoder = new window.google.maps.Geocoder();
    setPosition(latLng);
    props.handlePositionResult(latLng);
    geocoder.geocode({ location: latLng }, props.handleGeocoderResult);
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_TOKEN,
    libraries: ['places'],
  });

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={props.containerStyle}
        center={mapCenter}
        zoom={zoom}
        onClick={handleMapClick}
        onLoad={mapLoaded}
      >
        {position && (
          <MarkerF
            draggable={false}
            position={position}
            onDragEnd={(e) => {
              props.handlePositionResult(e.latLng);
            }}
          />
        )}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <Spinner />;
});

function SiteLocationStep({ step, setStep }) {
  const mapRef = useRef();

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const center = {
    lat: 29.47873429689,
    lng: -95.14606596,
  };

  let markerPos = null;

  const [stepValues, setStepValues] = useAppState();

  const { register, setError, handleSubmit, formState, watch, reset, setValue, getValues, trigger } = useForm({
    defaultValues: stepValues,
    mode: 'onSubmit',
  });
  const { errors } = formState;

  if (stepValues.lat && stepValues.lat != null && stepValues.lat != '') {
    center.lat = stepValues.lat || 29.47873429689;
    center.lng = stepValues.lon || -95.14606596;
    markerPos = {};
    markerPos.lat = stepValues.lat;
    markerPos.lng = stepValues.lon;
  }

  const SearchField = memo(() => {
    return (
      <div className={'mb-3'}>
        <WrappedInput
          {...register('compAddress', { required: 'Search address or click on map to set location' })}
          name={'compAddress'}
          placeholder="Search address or click on map to set location"
          className={''}
        />
      </div>
    );
  });

  const Map = memo(() => {
    return (
      <MapComponent
        mapCenter={center}
        markerPostion={markerPos}
        containerStyle={containerStyle}
        ref={mapRef}
        handleGeocoderResult={(result) => {
          if (result && result[0]) {
            setValue('compAddress', result[0].formatted_address);
          }
        }}
        handlePositionResult={(result) => {
          if (result) {
            setValue('lat', result.lat());
            setValue('lon', result.lng());
          }
        }}
        mapLoaded={(map, props, mapLoadedContext) => {
          const input = document.getElementsByName('compAddress')[0];
          // Specify just the place data fields that you need.
          const autocomplete = new google.maps.places.Autocomplete(input, {
            fields: ['place_id', 'geometry', 'name', 'formatted_address'],
          });

          let markerPos = {};
          markerPos.lat = getValues('lat');
          markerPos.lng = getValues('lon');

          if (markerPos.lat) {
            mapLoadedContext.current.setMarkerPositionAndCenterMap(markerPos);
          }

          // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (!place.place_id) {
              return;
            }
            const geocoder = new google.maps.Geocoder();

            geocoder
              .geocode({ placeId: place.place_id })
              .then(({ results }) => {
                mapLoadedContext.current.handleGeoCoderResult(results[0].geometry.location);
                props.handlePositionResult(results[0].geometry.location);
              })
              .catch((e) => toast.error('Geocoder failed due to: ' + e));
          });
        }}
      ></MapComponent>
    );
  });

  let reportTitle = watch('reportTitle');
  useEffect(() => {
    if (reportTitle !== 'undefined') {
      trigger('reportTitle');
    }
  }, [reportTitle]);

  return (
    <GenericStepForm step={step} setStep={setStep} submitHandler={handleSubmit}>
      <div className="section-header">
        <img src={siteLocationIcon} alt={''} />
        <h2 className="section-heading">Site Location</h2>
      </div>
      <div className="row">
        <div className="mb-1">
          <WrappedInput
            {...register('reportTitle', {
              required: 'Report title is required',
              validate: (value) =>
                !AllRegex.specialCharRegex.test(value) ||
                ' Only hypen(-),Underscore(_) and Space are allowed in report title',
            })}
            type="text"
            name={'reportTitle'}
            placeholder="Report Title"
            className="required-field"
            onFocus={async () => {
              trigger('reportTitle');
            }}
            onBlur={async () => {
              trigger('reportTitle');
            }}
          />
          <ErrorMessage errors={errors} name="reportTitle" as={'p'} className={'error-msg'} />
        </div>
      </div>
      <div className="row">
        <div className="mb-1">
          <SearchField />
        </div>
      </div>

      <div className="row">
        <div className={'container map-responsive'}>
          <Map />
        </div>
      </div>
      <div className={'row'}>
        <WrappedInput {...register('lon', { required: 'Location is required from map' })} type="hidden" name={'lon'} />
        <WrappedInput {...register('lat')} type="hidden" name={'lat'} />
        <ErrorMessage errors={errors} name="compAddress" as={'p'} className={'error-msg'} />
      </div>
    </GenericStepForm>
  );
}

export default SiteLocationStep;
