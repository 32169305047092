import { Link } from 'react-router-dom';
import SubHeader from './sub-header';
import { FormGroup, Input, Label } from 'reactstrap';
import CopyRightComponent from './copyright';
import { axiosUnSecureInstance } from '../services/axio-service';

import { useForm, Controller } from 'react-hook-form';
import { WrappedInput } from '../components/WrappedInput';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AllRegex } from '../validation';

function ContactUsComponent() {
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  console.log('errors', errors);

  const onSubmit = async (data) => {
    try {
      const res = await axiosUnSecureInstance.post('/contactus/', data);
      if (res.data.status == 'success') {
        reset();
        toast.success('Thank You for your query. We will respond  back');
      }
    } catch (error) {
      console.log('errr', error);
      toast.error(error);
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const helpArea = watch('helpArea');

  useEffect(() => {
    if (firstName !== undefined) {
      trigger('firstName');
    }
    if (lastName !== undefined) {
      trigger('lastName');
    }
    if (email !== undefined) {
      trigger('email');
    }
    if (email !== undefined) {
      trigger('helpArea');
    }
  }, [firstName, trigger, lastName, email, helpArea]);
  return (
    <div>
      <section className="  contact-hero-area">
        <SubHeader />
        <div className="payment-hero-main">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <div className="text-white">
                  <h2 className="wow contactUsTitle fadeInUp text-white" data-wow-delay=".2s">
                    Thanks for Stopping By!
                  </h2>
                  <p className="wow fadeInUp contactUsDesc" data-wow-delay=".4s">
                    Do you have a question about our technology? Wondering how it works? Have some other inquiry?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section  ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title mb-0 white-bg">
                <h3 className="wow fadeInUp" data-wow-delay=".2s">
                  Let's talk
                </h3>
                <h2 className="dot wow fadeInUp" data-wow-delay=".4s">
                  Share your excitement with us
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Fill out our form below or reach us using one of the below contact methods, and we’ll be in touch
                  shortly to answer your questions.{' '}
                </p>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-4">
                <FormGroup floating>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'First name is required',
                      pattern: {
                        value: AllRegex.onlyAlphabets,
                        message: 'Only alphabets are allowed',
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <WrappedInput
                          {...field}
                          placeholder="First Name"
                          onChange={(e) => {
                            setValue('firstName', e.target.value);
                          }}
                          onFocus={() => {
                            trigger('firstName');
                          }}
                          onBlur={() => {
                            trigger('firstName');
                          }}
                          type="text"
                        />
                      </>
                    )}
                  />
                  {errors.firstName && (
                    <p
                      style={{
                        color: 'red',
                      }}
                    >
                      {errors.firstName.message}
                    </p>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup floating>
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Last name is required',
                      pattern: {
                        value: AllRegex.onlyAlphabets,
                        message: 'Only alphabets are allowed',
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          placeholder="Last Name"
                          type="text"
                          onChange={(e) => {
                            setValue('lastName', e.target.value);
                          }}
                          onFocus={() => {
                            trigger('lastName');
                          }}
                          onBlur={() => {
                            trigger('lastName');
                          }}
                        />
                        <Label className="px-2"> Last Name</Label>
                      </>
                    )}
                  />
                  {errors.lastName && (
                    <p
                      style={{
                        color: 'red',
                      }}
                    >
                      {errors.lastName.message}
                    </p>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup floating>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Email is required',
                      pattern: {
                        value: AllRegex.email,
                        message: 'Please enter a valid email address',
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          placeholder="Email"
                          type="email"
                          onChange={(e) => {
                            setValue('email', e.target.value);
                          }}
                          onFocus={() => {
                            trigger('email');
                          }}
                          onBlur={() => {
                            trigger('email');
                          }}
                        />
                        <Label className="px-2"> Email</Label>
                      </>
                    )}
                  />
                  {errors.email && (
                    <p
                      style={{
                        color: 'red',
                      }}
                    >
                      {errors.email.message}
                    </p>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup floating>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Phone number is required',
                      validate: (value) => validatePhoneNumber(value) || 'Invalid phone number',
                    }}
                    render={({ field }) => (
                      <>
                        <div>
                          <label>
                            Phone Number:
                            <PhoneInput
                              country={'us'}
                              value={watch('phone')}
                              onChange={(value) => {
                                setValue('phone', value);
                                field.onChange(value);
                              }}
                              inputProps={{
                                required: true,
                              }}
                            />
                          </label>
                        </div>
                      </>
                    )}
                  />
                  {errors.phone && (
                    <p
                      style={{
                        color: 'red',
                      }}
                    >
                      {errors.phone.message}
                    </p>
                  )}
                </FormGroup>
              </div>

              {/* Other form fields */}

              <div className="col-md-12">
                <FormGroup floating data-wow-delay=".3s">
                  <Controller
                    name="helpArea"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'required',
                      pattern: {
                        value: AllRegex.onlyAlphabets,
                        message: 'Only alphabets are allowed',
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          placeholder="Leave a comment here"
                          type="textarea"
                          rows="15"
                          className="textarea"
                          onChange={(e) => {
                            setValue('helpArea', e.target.value);
                          }}
                          onFocus={() => {
                            trigger('helpArea');
                          }}
                          onBlur={() => {
                            trigger('helpArea');
                          }}
                        />
                        <Label className="px-2">Please tell us how we can help</Label>
                      </>
                    )}
                  />
                  {errors.helpArea && (
                    <p
                      style={{
                        color: 'red',
                      }}
                    >
                      {errors.helpArea.message}
                    </p>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-12">
                <div className="button wow fadeInUp" data-wow-delay=".3s">
                  <button type="submit" className="btn btn-icon send-icon" disabled={  errors && Object.keys(errors).length===0 ? false : true }>
                    Send 
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <CopyRightComponent />
    </div>
  );
}

export default ContactUsComponent;
