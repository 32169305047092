import { FormGroup, Input, Label } from 'reactstrap';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { ErrorMessage } from '@hookform/error-message';
import { MESSAGES_CONSTANTS } from '../store/messages';
import { WrappedInput } from './WrappedInput';

export const WrappedNumberInput = (props) => {
  return (
    <>
      <WrappedInput
        type={'number'}
        {...props.registerhandler(props.name, {
          required: props.required === true ? `${props.requiredtext || 'Required'}` : false,
          valueAsNumber: true,
          min: { value: props.min, message: MESSAGES_CONSTANTS.VALUE_MUST_BE_GREATER_OR_EQUALS_THAN(props.min) },
          max: { value: props.max, message: MESSAGES_CONSTANTS.VALUE_MUST_BE_LESS_OR_EQUALS_THAN(props.max) },
          validate: props.validate,
          disabled: props.disabled,
        })}
        {...props}
        className={`${props.required === true ? 'required-field' : ''}  ${props.className}`}
      />

      {props.errors !== undefined && (
        <ErrorMessage errors={props.errors} name={`${props.name}`} as={'p'} className={'error-msg'} />
      )}
    </>
  );
};
