import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Main from './pages/main';
import Header from './pages/header';
import ContactUsComponent from './pages/contact-us';
import GettingStarted from './pages/getting-started';
import ProjectionPage from './pages/projection';
import ValuationPage from './pages/valuation';
import FormWizard from './pages/form-wizard';

import { useEffect, useState } from 'react';
import Emitter from './components/Emitter';
import LoginSignUpBox from './components/LoginSignUp';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from './hooks/useAuth';
import ResetPassword from './pages/reset-password';
import SubmittedReports from './pages/submitted-reports';
import PrivateRoute from './components/PrivateRoute';
import { AppProvider } from './hooks/useAppState';
import PrivacyPolicy from './pages/privacy-policy';
import Payment from './pages/payment';
import CheckoutPage from './pages/checkout';
import Leads from './pages/leads';
import Userprofile from './pages/userprofile';
import Vendor from './pages/Vendor';
import LeadsDetail from './pages/LeadsDetail';
import NotFound from './components/NotFound';
import HowToUse from './pages/how-to-use';
import VendorPage from './pages/VendorPage';
import Otp from './pages/otp';
import { RemainingReportProvider } from './hooks/useRemainingReportSate';
import useMobileDetection from './hooks/useMobileDetection';
import MobileModel from './components/model/MobileModel';
import Team from './pages/team';
function App() {
  const [showModal, setShowModal, browser] = useMobileDetection();

  console.log('browser', browser);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const Layout = () => {
    return (
      <>
        <LoginSignUpBox />
        <Header />
        <Outlet />
      </>
    );
  };

  const routers = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Main />,
        },
        {
          path: '/how-to-use',
          element: <HowToUse />,
        },
        {
          path: '/contact-us',
          element: <ContactUsComponent />,
        },
        {
          path: '/getstarted',
          element: <GettingStarted />,
        },
        {
          path: '/payment',
          element: <Payment />,
        },
        {
          path: '/profile',
          element: <PrivateRoute element={<Userprofile />} />,
        },
        // {
        //   path: '/vendor',
        //   element: <Vendor />,
        // },

        {
          path: '/lead-detail/:id/:read',
          element: <PrivateRoute element={<LeadsDetail />} />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
        {
          path: '/stripepayment',
          element: <CheckoutPage />,
        },
        {
          path: '/projection',
          element: <ProjectionPage />,
        },
        {
          path: '/valuation',
          element: <ValuationPage />,
        },
        {
          path: '/user-aggrement',
          element: <PrivacyPolicy />,
        },
        {
          path: '/leads',
          element: <PrivateRoute element={<Leads />} />,
        },
        {
          path: '/vendor',
          element: <PrivateRoute element={<VendorPage />} />,
        },
        {
          path: '/team',
          element:<Team/>
        },

        
        {
          path: '/otp',
          element: <Otp />,
        },
        {
          path: '/wizard',
          element: (
            <AppProvider>
              <FormWizard />
            </AppProvider>
          ),
        },
        {
          path: '/submittedreports',
          element: <PrivateRoute element={<SubmittedReports />} />,
        },
      ],
    },
    {
      children: [
        {
          path: '/setpassword',
          element: <ResetPassword />,
        },
      ],
    },
    
  ]);

  return (
    <div className="App">
      <MobileModel show={showModal} handleClose={handleCloseModal}>
        <p>You can use our mobile app for a better experience!</p>
        <a
          href={
            browser === 'safari'
              ? 'https://apps.apple.com/us/app/tezintel/id6470182263'
              : 'https://play.google.com/store/apps/details?id=com.tezIntelMobile'
          }
          target="_blank"
          className="button"
        >
          Download the App
        </a>
      </MobileModel>
      <RemainingReportProvider>
        <RouterProvider router={routers} />
      </RemainingReportProvider>
    </div>
  );
}

export default App;