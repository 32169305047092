import React from 'react';
import teamCeo from '../assets/images/team/ceo.jpg';
import team1 from '../assets/images/team/team1.jpg';
import team2 from '../assets/images/team/team2.jpg';
import ceo from '../assets/images/team/ceo1.jpg';
import ramos from '../assets/images/team/ramos.jpg';
import Avatar from '../assets/images/team/Avatar.png';
import '../assets/css/team.css';
import { FaFacebook, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import SubHeader from './sub-header';

const Team = () => {
  let teamData = [
    {
      name: 'Naveed Ali',
      desc: 'CEO & Founder',
      img: ceo,
      social: [
        {
          icon: <FaFacebook size={18} color="#484E5F" />,
          link: 'https://www.facebook.com/',
        },
        {
          icon: <FaLinkedin size={18} color="#484E5F" />,
          link: 'https://www.linkedin.com/',
        },
        {
          icon: <FaWhatsapp size={18} color="#484E5F" />,
          link: 'https://wa.me/918767767676',
        },
      ],
    },
    {
      name: 'Curt Lockhart',
      desc: 'Managing Attorney',
      img: team2,
      social: [
        {
          icon: <FaFacebook size={18} color="#484E5F" />,
          link: 'https://www.facebook.com/',
        },
        {
          icon: <FaLinkedin size={18} color="#484E5F" />,
          link: 'https://www.linkedin.com/',
        },
        {
          icon: <FaWhatsapp size={18} color="#484E5F" />,
          link: 'https://wa.me/918767767676',
        },
      ],
    },
    {
      name: 'Ernie Ramos',
      desc: 'Lead Enginner',
      img:ramos,
      social: [
        {
          icon: <FaFacebook size={18} color="#484E5F" />,
          link: 'https://www.facebook.com/',
        },
        {
          icon: <FaLinkedin size={18} color="#484E5F" />,
          link: 'https://www.linkedin.com/',
        },
        {
          icon: <FaWhatsapp size={18} color="#484E5F" />,
          link: 'https://wa.me/918767767676',
        },
      ],
    },
    {
      name: 'Bob Lyons',
      desc: 'Site Location & Development',
      img: Avatar,
      social: [
        {
          icon: <FaFacebook size={18} color="#484E5F" />,
          link: 'https://www.facebook.com/',
        },
        {
          icon: <FaLinkedin size={18} color="#484E5F" />,
          link: 'https://www.linkedin.com/',
        },
        {
          icon: <FaWhatsapp size={18} color="#484E5F" />,
          link: 'https://wa.me/918767767676',
        },
      ],
    },
   
    {
      name: ' ',
      desc: 'CFO',
      img: Avatar,
      social: [
        {
          icon: <FaFacebook size={18} color="#484E5F" />,
          link: 'https://www.facebook.com/',
        },
        {
          icon: <FaLinkedin size={18} color="#484E5F" />,
          link: 'https://www.linkedin.com/',
        },
        {
          icon: <FaWhatsapp size={18} color="#484E5F" />,
          link: 'https://wa.me/918767767676',
        },
      ],
    },
    // {
    //   name: '',
    //   desc: '',
    //   img: Avatar,
    //   social: [
    //     {
    //       icon: <FaFacebook size={18} color="#484E5F" />,
    //       link: 'https://www.facebook.com/',
    //     },
    //     {
    //       icon: <FaLinkedin size={18} color="#484E5F" />,
    //       link: 'https://www.linkedin.com/',
    //     },
    //     {
    //       icon: <FaWhatsapp size={18} color="#484E5F" />,
    //       link: 'https://wa.me/918767767676',
    //     },
    //   ],
    // },
    // {
    //   name: '',
    //   desc: '',
    //   img: Avatar,
    //   social: [
    //     {
    //       icon: <FaFacebook size={18} color="#484E5F" />,
    //       link: 'https://www.facebook.com/',
    //     },
    //     {
    //       icon: <FaLinkedin size={18} color="#484E5F" />,
    //       link: 'https://www.linkedin.com/',
    //     },
    //     {
    //       icon: <FaWhatsapp size={18} color="#484E5F" />,
    //       link: 'https://wa.me/918767767676',
    //     },
    //   ],
    // },
   
  ];
  return (
    <div>
      <SubHeader teamPage={true}/>
      <div className="teamWrapper">
        <div className="teamDiv">
          <p className="teamTitle">Our Executive Team</p>
          <p className="teamDesc">
            {' '}
            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque natus excepturi ipsa cumque, laborum facilis
            rem iure doloribus iusto perspiciatis repellendus ullam soluta possimus exercitationem veniam temporibus
            accusantium sunt! Expedita! */}
          </p>
        </div>
        <div className="teamCardWrapper container">
          {teamData.map((item) => (
            <div className="cardContent ">
              <div style={{
                background: `url(${item.img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                width: '150px',
                height: '150px',
                borderRadius: '50%',
                margin:'auto',
                // filter:"grayscale(100%)"
              }} className='teamImageContainer' >
                 
              </div>
              {/* <img className="cardimg" src={item.img} width={200} height={200} alt="ceo" /> */}
              <p className="title">{item.name}</p>
              <p className="desc">{item.desc}</p>
              {/* <div className="socialIcon">
                <ul className="socialIconContent">
                  {item.social.map((social) => (
                    <li>
                      {' '}
                      <a href={social.link} target="_blank">
                        {' '}
                        {social.icon}
                      </a>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
