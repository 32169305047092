import { Link } from 'react-router-dom';

import logo from '../assets/images/logo/logo.png';
import logo2 from '../assets/images/logo/logo3.png';
import { useAuth } from '../hooks/useAuth';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

function SubHeader({ teamPage }) {
  const { user } = useAuth();
  const location = useLocation();

  const { pathname } = location;
  const [isToggle, setIsToggle] = useState(false);
  const [isdropDownToggle, setIsDropdownToggle] = useState(false);

  return (
    <header className="header navbar-area  ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="nav-inner">
              <nav className="navbar navbar-expand-lg">
                <Link to={'/'}>
                  <img className="brand-logo" src={ teamPage ? logo2 : logo} alt="Logo" />
                </Link>
                <button
                  className="navbar-toggler mobile-menu-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => setIsToggle(!isToggle)}
                >
                  <span className={ teamPage ? "toggler-icon toggler-team":"toggler-icon"}></span>
                  <span className={ teamPage ? "toggler-icon toggler-team":"toggler-icon"}></span>
                  <span className={ teamPage ? "toggler-icon toggler-team":"toggler-icon"}></span>
                </button>
                <div
                  className={
                    isToggle
                      ? 'collapse navbarToggle navbar-collapse sub-menu-bar'
                      : 'collapse navbar-collapse sub-menu-bar'
                  }
                >
                  <ul id="nav" className="navbar-nav ms-auto navbar-mb">
                    <li className={teamPage ? 'nav-item team' : 'nav-item'}>
                      <Link to={'/'} className={pathname == '/' ? 'active' : ''}>
                        Home
                      </Link>
                    </li>
                    <li className={teamPage ? 'nav-item team' : 'nav-item'}>
                      <Link
                        to={'/payment'}
                        className={pathname == '/payment' ? 'active' : ''}
                        aria-label="Toggle navigation"
                      >
                        Payment
                      </Link>
                    </li>
                    {user && (
                      <li className={teamPage ? 'nav-item team' : 'nav-item'}>
                        <Link to={'/submittedreports'} className={pathname == '/submittedreports' ? 'active' : ''}>
                          Submitted Reports
                        </Link>
                      </li>
                    )}
                    <li className={teamPage ? 'nav-item team' : 'nav-item'}>
                      <a
                        // className=""
                        href="javascript:void(0)"
                        data-bs-toggle="collapse"
                        data-bs-target="#submenu-1-2"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() => setIsDropdownToggle(!isdropDownToggle)}
                        className={
                          pathname == '/projection' || pathname == '/valuation'
                            ? 'dd-menu collapsed active'
                            : 'dd-menu collapsed'
                        }
                      >
                        Services
                      </a>
                      <ul
                        // className="sub-menu collapse"

                        className={isdropDownToggle ? 'sub-menu sub-menu-dropdown collapse' : 'sub-menu collapse'}
                        id="submenu-1-2"
                      >
                        <li className="nav-item">
                          <Link to={'/projection'} className={pathname == '/projection' ? 'active' : ''}>
                            Financial Projection
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={'/valuation'} className={pathname == '/valuation' ? 'active' : ''}>
                            Real Estate Valuation
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className={teamPage ? 'nav-item team' : 'nav-item'}>
                      <Link
                        to={'/how-to-use'}
                        className={pathname == '/how-to-use' ? 'active' : ''}
                        aria-label="Toggle navigation"
                      >
                        How to use Tezintel
                      </Link>
                    </li>
                    <li className={teamPage ? 'nav-item team' : 'nav-item'}>
                      <Link
                        to={'/contact-us'}
                        className={pathname == '/contact-us' ? 'active' : ''}
                        aria-label="Toggle navigation"
                      >
                        Contact Us
                      </Link>
                    </li>
                    {/* <li className={teamPage ? 'nav-item team' : 'nav-item'}>
                      <Link to={'/team'} aria-label="Toggle navigation"  className={pathname == '/team' ? 'active' : ''}
>
                        Team
                      </Link>
                    </li> */}
                    <li className={teamPage ? 'nav-item team' : 'nav-item button'}>
                      <Link to={'/getstarted'} aria-label="Toggle navigation" className={'btn'}>
                        Get Started
                      </Link>
                    </li>
                   
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default SubHeader;
