import SubHeader from './sub-header';
import { Alert, Button, Container, FormGroup, Input, Label } from 'reactstrap';
import PricingPlan from '../components/PricingPlan';
import { Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosInstance from '../services/axio-service';

function CheckoutPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const canceled = searchParams.get('canceled');
  const success = searchParams.get('success');
  let requestProcessing = false;

  const [paymentStatus, setPaymentStatus] = useState(success === 'true');

  useEffect(() => {
    if (paymentStatus) {
      if (requestProcessing) {
        return;
      }

      requestProcessing = true;

      //   const sessionId = searchParams.get('session_id');
      //   const pricePlan = searchParams.get('pricePlan');
      //   if (sessionId) {
      //     axiosInstance
      //       .post('/subscribed-product/', { sessionId: sessionId, pricePlan: pricePlan })
      //       .then((response) => {
      //         const timerDelay = 5000; // 5000 milliseconds = 5 seconds
      //
      //         // Set the timer to close the window after the specified delay
      //         const timer = setTimeout(() => {
      //           window.close();
      //         }, timerDelay);
      //       })
      //       .catch((error) => {
      //         // Handle the error if needed
      //       });
      //   }
    }
  }, []);

  return (
    <div>
      {/* <SubHeader />
      <section className="hero-area contact-hero-area">
        <div className="container w-auto">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <div className="hero-content">
                <h2 className="wow fadeInUp" data-wow-delay=".2s">
                  Payments
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".4s"></p>
              </div>
            </div>
          </div>
        </div>
        
      </section> */}
         <section className="  contact-hero-area">
        <SubHeader />
        <div className='payment-hero-main'>
        <div className="container w-auto">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <div className="text-white">
                <h2 className="wow fadeInUp contactUsTitle paymentText" data-wow-delay=".2s">
                  Payments
                </h2>
                <p className="paysubText  ">Explore Our Flexible Payment Plans for Your Project</p>
              </div>
            </div>
          </div>
        </div>
        </div>
     
      </section>
      <section className="section form-section">
        <Container className="mt-5">
          {paymentStatus === true ? (
            <>
              <Alert color="success">Payment was successful!</Alert>
              <Link to={'/'}>Redirect to HomePage</Link>
            </>
          ) : paymentStatus === false ? (
            <>
              <Alert color="danger">Payment was canceled!</Alert>
              <Link to={'/'}>Redirect to HomePage</Link>
            </>
          ) : (
            <></>
          )}
        </Container>
      </section>
    </div>
  );
}

export default CheckoutPage;
